<template>
  <div class="py-12 px-120px bg-white mdmax:px-4">
    <div class="container flex flex-col gap-12 mdmax:gap-6">
      <div class="text-2xl font-bold text-center px-10">{{ $t('installment.completeApp.title') }}</div>
      <div class="flex gap-5 mdmax:inline">
        <div
          class="bg-white grid gap-2 md:gap-4 p-2 md:p-4 w-1/2 rounded-lg border-2 border-neutral-100 mdmax:w-full mdmax:mb-4 content-start"
          v-for="(appItems, appIndex) in appProcessItems"
          :key="appItems + appIndex + 'App'"
        >
          <div class="flex-1 flex flex-row md:justify-center md:flex-col items-center">
            <div class="flex rounded-full w-8 h-8 md:w-16 md:h-16 justify-center border border-primary-400 bg-yellow-100 items-center text-primary-400 font-semibold text-lg md:text-2xl">
              {{ appIndex + 1 }}
            </div>
            <div class="text-base md:text-lg font-bold md:text-center pl-4 md:pl-0 md:pt-4">{{ appItems.title }}</div>
          </div>
          <div class="text-sm pb-1 pl-12 md:pl-0 md:text-center">
            {{ appItems.desc }}
            <a v-if="appItems.link" href="https://danacita.co.id/partners/G2Academy/" target="_blank" class="text-primary-400 font-semibold">{{ appItems.link }}</a>
          </div>
        </div>
      </div>
      <div class="flex w-full flex-col xl:flex-row justify-center items-center gap-6 mdmax:flex-col mdmax:gap-2">
        <p class="text-right mdmax:text-center mdmax:text-sm mdmax:mb-3 w-full">{{ $t('installment.completeApp.desc') }}</p>
        <div class="flex w-1/2 mdmax:flex-col mdmax:w-full gap-6 mdmax:gap-2">
          <Button :buttonText="`${$t('installment.completeApp.btn1')}`" type="secondary" size="small" @action="jadwalSesi" additionalClass="py-3 px-5 w-3/5 mdmax:w-auto"></Button>
          <Button :buttonText="`${$t('installment.completeApp.btn2')}`" type="tertiary" size="small" @action="waCs" additionalClass="py-3 w-2/5 mdmax:w-auto"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppProcess',
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  methods: {
    jadwalSesi() {
      window.open('https://danacita.co.id/s/jadwalkansesi')
    },
    waCs() {
      window.open('https://wa.me/6281212876886')
    }
  },
  computed: {
    activeLanguange() {
      return this.$i18n.locale
    },
    appProcessItems() {
      return this.$i18n.messages[this.activeLanguange].installment.completeApp.items
    }
  }
}
</script>

<style></style>
